import { useParams } from 'react-router-dom';
import { Empty, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import MyCard from '../components/MyCard';
import Breadcrumb from '../components/Breadcrumb';

const EventDetails = () => {
    const { title } = useParams(); // Get the event title from the URL
    const [eventData, setEventData] = useState(null);
    const [activeDay, setActiveDay] = useState('Day1'); // Track the selected day tab

    const apiKey = process.env.REACT_APP_PUBLIC_API_KEY;
    const secretKey = process.env.REACT_APP_PUBLIC_SECRET_KEY;
    const REACT_BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        fetchEventDetails(title); // Fetch details using event name
    }, [title, REACT_BASE_URL, apiKey, secretKey]);

    // Function to fetch event details by event name
    const fetchEventDetails = (eventName) => {
        fetch(`${REACT_BASE_URL}/api/resource/Events/${eventName}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${apiKey}:${secretKey}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.data) {
                    setEventData(data.data); // Store event data
                }
            })
            .catch((error) => {
                console.error(`Error fetching details for ${eventName}:`, error);
            });
    };

    const handleTabChange = (key) => {
        setActiveDay(key)
            ; // Update active day based on tab key
    };

    // Filter images based on active day
    const filteredImages = eventData
        ? eventData.events_images_list.filter((imageData) => imageData.day_wise === activeDay)
        : [];

    const eventItems = [
        { key: 'Day1', label: <>Day 1</> },
        { key: 'Day2', label: <>Day 2</> },
        { key: 'Day3', label: <>Day 3</> },
        { key: 'Day4', label: <>Day 4</> },
        { key: 'Day5', label: <>Day 5</> },
        { key: 'Day6', label: <>Day 6</> },
        { key: 'Day7', label: <>Day 7</> },
        { key: 'Day8', label: <>Day 8</> },
        { key: 'Day9', label: <>Day 9</> },
    ];

    return (
        <section className='container mx-auto'>
            <Breadcrumb page={'Event Gallery'} />
            <div className='container-top event-tab'>
                <Tabs

                    type="card"
                    items={eventItems}
                    onChange={handleTabChange}
                />
            </div>

            <div className='container mx-auto container-bottom container-top'>
                {filteredImages.length > 0 ? (
                    <div className=' grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-14'>
                        {filteredImages.map((imageData, index) => (
                            <div key={index} className="cursor-pointer">
                                <MyCard
                                    CardClassNames=" "
                                    imageSrc={`${REACT_BASE_URL}${imageData.event_image}`} // Use the image path
                                    // ContentDivClassNames="text-center"
                                    imageClassNames="w-full h-full object-fit"
                                // headingClassNames="text-lg md:text-xl lg:text-2xl text-[#081340] font-bold pt-3"
                                // contentClassNames="text-[#70768e] text-base md:text-lg py-2"
                                />
                            </div>
                        ))}

                    </div>
                ) : (
                    <Empty description={'Images will be launching soon. Stay tuned!'} image={Empty.PRESENTED_IMAGE_SIMPLE} className='h-[100px] md:h-[300px]' />
                )}
            </div>
        </section>
    )
}

export default EventDetails