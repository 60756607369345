import React from 'react';
import { DeploymentUnitOutlined, InboxOutlined, FullscreenOutlined, SlackOutlined, AppstoreAddOutlined, BranchesOutlined } from '@ant-design/icons';

const icons = [
    { id: 1, icon: <DeploymentUnitOutlined />, description: 'No Sugar' },
    { id: 2, icon: <FullscreenOutlined />, description: '20G Protein' },
    { id: 3, icon: <SlackOutlined />, description: 'Naturally occurring' },
    { id: 4, icon: <AppstoreAddOutlined />, description: '100% Pure' },
    { id: 5, icon: <BranchesOutlined />, description: 'Keto-friendly' },
    { id: 6, icon: <InboxOutlined />, description: 'Open Outlet' },
];

const IconGrid = () => {
    return (
        <section className='animate-bounce-up container mx-auto'>
            <div className="container-outer">
                <div className="flex justify-around icon-widget">
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
                        {icons.map((item) => (
                            <div key={item.id} className="flex flex-col items-center p-4 transition-shadow duration-300">
                                <div className="text-3xl mb-2 text-primary">{item.icon}</div>
                                <p className="text-lg text-primary text-center mt-5">{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IconGrid