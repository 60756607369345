import { useState, useEffect } from 'react';

// Define breakpoints
const breakpoints = {
  xs: 0,    // Extra small
  sm: 576,  // Small
  md: 768,  // Medium
  lg: 992,  // Large
  xl: 1200, // Extra large
  xxl: 1400, // Double extra large
  xxxl: 1530,
};

const useResponsiveSizes = () => {
  const [currentSizes, setCurrentSizes] = useState(getCurrentSizes(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setCurrentSizes(getCurrentSizes(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return currentSizes;
};

const getCurrentSizes = (width) => {
  return {
    xs: width < breakpoints.sm,
    sm: width >= breakpoints.sm && width < breakpoints.md,
    md: width >= breakpoints.md && width < breakpoints.lg,
    lg: width >= breakpoints.lg && width < breakpoints.xl,
    xl: width >= breakpoints.xl && width < breakpoints.xxl,
    xxl: width >= breakpoints.xxl && width < breakpoints.xxxl,
    xxxl: width >= breakpoints.xxxl,
  };
};

export default useResponsiveSizes;
