
import React, { useEffect, useState } from 'react';
import MyCard from '../components/MyCard';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';

const Events = () => {
    const navigate = useNavigate(); // Use React Router's useNavigate hook
    const [detailedEvents, setDetailedEvents] = useState([]); // For storing detailed event data

    const apiKey = process.env.REACT_APP_PUBLIC_API_KEY;
    const secretKey = process.env.REACT_APP_PUBLIC_SECRET_KEY;
    const REACT_BASE_URL = process.env.REACT_APP_BASE_URL;
    

    // Fetch basic event data
    useEffect(() => {
        fetch(`${REACT_BASE_URL}/api/resource/Events`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${apiKey}:${secretKey}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.data) {
                    // Fetch detailed data for each event by name
                    data.data.forEach((event) => {
                        fetchEventDetails(event.name); // Fetch details using event name
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching events:", error);
            });
    }, [REACT_BASE_URL, apiKey, secretKey]);

    // Function to fetch detailed data for each event
    const fetchEventDetails = (eventName) => {
        fetch(`${REACT_BASE_URL}/api/resource/Events/${eventName}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `token ${apiKey}:${secretKey}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data && data.data) {
                    setDetailedEvents((prevEvents) => [...prevEvents, data.data]); // Append detailed data
                }
            })
            .catch((error) => {
                console.error(`Error fetching details for ${eventName}:`, error);
            });
    };

    // Format the title for URL (replace spaces with dashes and make lowercase)
    const formatTitleForURL = (title) => {
        return title.replace(/\s+/g, '-').toLowerCase();
    };

    // Handle the card click and redirect to /event-title
    const handleCardClick = (title) => {
        const formattedTitle = formatTitleForURL(title);
        navigate(`/events/${formattedTitle}`); // Redirect to /events/event-title
    };
    return (
        <section className='container mx-auto container-bottom'>
            <Breadcrumb page={'Events'}/>
            <div className='lg:mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 lg:gap-14'>
                {detailedEvents.map((data, index) => {
                    const imageSrc = `${REACT_BASE_URL}${data.event_banner}`;

                    return (

                        <div
                            key={index}
                            onClick={() => handleCardClick(data.event_name)} // Handle click
                            className="cursor-pointer"
                        >
                            <MyCard
                                CardClassNames=" "
                                imageSrc={imageSrc}
                                title={data.event_name}
                                ContentDivClassNames="text-center"
                                imageClassNames="w-full"
                                headingClassNames="text-lg md:text-xl lg:text-2xl text-[#081340] font-bold pt-3"
                                contentClassNames="text-[#70768e] text-base md:text-lg py-2"
                            />
                        </div>
                    )
                }

                )}
            </div>
        </section>


    )
}

export default Events