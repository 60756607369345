import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import call from "../assets/call.svg"
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Breadcrumb from '../components/Breadcrumb';



// Define Zod schema for validation
const formSchema = z.object({
    Name: z.string().min(1, { message: "Name is required" }).min(3, { message: "Name must be at least 3 characters" }),
    Phone: z.string().min(1, { message: "Phone Number is required" }).min(10, { message: "Number must be at least 10 digits" }),
    email: z.string().min(1, { message: "Email is required" }).email({ message: "Invalid email address" }),
    message: z.string().min(1, { message: "Message is required" }).min(10, { message: "Message must be at least 10 characters" }),
});


const markers = [
    { position: [1.3521, 103.8198], popup: "Singapore" },
    { position: [28.6139, 77.2090], popup: "New Delhi" }
];

const Contact = () => {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: zodResolver(formSchema)
    });

    const onSubmit = (data) => {
        // console.log('Form Data:', data);
    };

    return (
        <>
            <section className='container-1'>
                <Breadcrumb page={'Contact'} />
                <div className='flex flex-col lg:flex-row gap-5 items-center'>
                    <div className='w-full lg:w-1/2 '>
                        <div className='bg-[#4ec5b617] p-10'>
                            <h3 className=' text-2xl md:text-3xl lg:text-4xl text-primary font-bold'>HEAD OFFICE</h3>
                            <div className='w-full'>

                                <div className='flex flex-row gap-5 border-b py-5 '>
                                    <img src={call} alt={call} />
                                    <div>
                                        <h4 className='text-primary  text-base md:text-lg lg:text-xl text-start'>Phone</h4>
                                        <h3 className='text-primary font-semibold text-base md:text-lg lg:text-xl' >021-8700-957</h3>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-5 border-b py-5 '>
                                    <img src={call} alt={call} />
                                    <div>
                                        <h4 className='text-primary  text-base md:text-lg lg:text-xl text-start'>Phone</h4>
                                        <h3 className='text-primary font-semibold text-base md:text-lg lg:text-xl' >021-8700-957</h3>
                                    </div>
                                </div>
                                <div className='flex flex-row gap-5 border-b py-5     '>
                                    <img src={call} alt={call} />
                                    <div>
                                        <h4 className='text-primary  text-base md:text-lg lg:text-xl text-start '>Phone</h4>
                                        <h3 className='text-primary font-semibold text-base md:text-lg lg:text-xl' >021-8700-957</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full lg:w-1/2'>
                        <h3 className='text-2xl md:text-3xl lg:text-4xl text-primary font-bold'>Get in touch
                        </h3>
                        <form onSubmit={handleSubmit(onSubmit)} className='mx-auto mt-10'>
                            <div className='w-full '>
                                <input
                                    type="text"
                                    id="Name"
                                    placeholder='Full Name'
                                    className={`py-5 px-5 rounded-[5px] border-[1px] border-solid w-full bg-[#f4f6fb]  ${errors.Name ? 'border-[#FF0000] outline outline-1 focus:outline-[#FF0000]' : 'border-[#808080]'}`}
                                    {...register("Name")}
                                />
                                {errors.Name && <p>{errors.Name.message}</p>}
                            </div>

                            <div className='w-full mt-5 '>
                                <input
                                    type="text"
                                    id="Phone"
                                    placeholder='Phone'
                                    className={`py-5 px-5 rounded-[5px] border-[1px] border-solid w-full bg-[#f4f6fb]  ${errors.Phone ? 'border-[#FF0000] outline outline-1 focus:outline-[#FF0000]' : 'border-[#808080]'}`}
                                    {...register("Phone")}
                                />
                                {errors.Phone && <p>{errors.Phone.message}</p>}
                            </div>



                            <div className='w-full mt-5 '>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Email"
                                    className={`py-5 px-5 rounded-[5px] border-[1px] border-solid w-full bg-[#f4f6fb]  ${errors.email ? 'border-[#FF0000] outline outline-1 focus:outline-[#FF0000]' : 'border-[#808080]'}`}
                                    {...register("email")}
                                />
                                {errors.email && <p>{errors.email.message}</p>}
                            </div>


                            <div className='w-full mt-5'>
                                <textarea
                                    id="message"
                                    placeholder="Write Message"
                                    className={`py-5 px-5 rounded-[5px] border-[1px] border-solid w-full bg-[#f4f6fb]  ${errors.message ? 'border-[#FF0000] outline outline-1 focus:outline-[#FF0000]' : 'border-[#808080]'
                                        }`}
                                    {...register("message")}
                                />

                                {errors.message && <p>{errors.message.message}</p>}
                            </div>
                            <button
                                type="submit"
                                className="mx-auto block bg-sky font-bold text-[white] py-5 px-5 rounded-sm transition-all duration-300 transform hover:bg-limegreen hover:scale-[90%] mt-5"
                            >
                                SEND MESSAGE
                            </button>
                        </form>
                    </div>
                </div>
            </section>


            <section className='container mx-auto h-[300px] my-10'>
                <MapContainer center={[51.505, -0.09]} zoom={2} height={400} scrollWheelZoom={true} className='mt-10'>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers.map((marker, index) => (
                        <Marker key={index} position={marker.position}>
                            <Popup>{marker.popup}</Popup>
                        </Marker>
                    ))}
                </MapContainer>

            </section>
        </>


    )
}

export default Contact