import React from 'react'

const Breadcrumb = ({page}) => {
    return (
        <div className=" h-[200px] lg:h-[400px] w-full bg-contain bg-center bg_image relative ">
            <h3 className='text-primary font-semibold text-base md:text-lg lg:text-xl text-start absolute left-11 lg:left-4 top-[5.5rem] lg:top-48 '>
                Home / {page}
            </h3>
        </div>
    )
}

export default Breadcrumb