import React from 'react'
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const MyCard = ({ imageSrc, title, imageClassNames, ContentDivClassNames, description, headingClassNames, contentClassNames, CardClassNames, showOrder }) => {
    return (
        <div className={`my-card cursor-pointer ${CardClassNames}`}>
            <img src={imageSrc} alt={title} className={`${imageClassNames}`}/>
            <div className={ContentDivClassNames}>
                <h3 className={`${headingClassNames}`}>{title}</h3>
                <p className={`${contentClassNames}`}>{description}</p>
            </div>
            {showOrder && (
                <div className='pt-2 text-center'>
                    <FaRegArrowAltCircleRight className='fill-[#ffa21d] mx-auto inline-block mb-1' />
                    <Link to="/contact"><span className="ml-2 text-[#31333e] font-['Exo_2',_sans-serif]">Contact Us</span></Link>
                </div>
            )}
            
        </div>
    )
}

export default MyCard