import React from 'react'
import { InstagramOutlined, LinkedinOutlined, FacebookFilled, YoutubeFilled } from '@ant-design/icons';
import bolltegrid from '../assets/Group 1.png'
import { Link } from 'react-router-dom';

function NewsLetter() {
    return (
        <>
            <section className="container mx-auto !mb-0">
                <div className="relative">
                    <div className="centered text-left">
                        <h2 className="text-2xl lg:text-5xl font-bold text-[white]">
                            Join Our Outlet
                        </h2>
                        <h2 className="text-2xl lg:text-5xl font-bold text-[white]">
                            With Juice.
                        </h2>
                        <div className='flex items-left mt-5 md:flex-1 flex-col md:flex-row'>
                            <Link to="/contact">
                                <button type="primary" className='rounded-none bg-primary hover:bg-[white] hover:scale-[90%] text-[white] hover:text-primary px-5 py-2'>Contact Us</button>
                            </Link>
                            <div className='flex gap-2 ml-3 mt-3 md:mt-0'>
                                <a href='https://www.facebook.com/people/Silver-Blue/61564584206686/' className="flex items-center justify-center w-8 h-8 rounded-full bg-primary text-[white] hover:text-primary hover:bg-[white]"><FacebookFilled /></a>
                                <a href='hhttps://www.instagram.com/silverbluewater/?igsh=MTk2NnZ2cWNlMzBzbg%3D%3D&utm_source=qr' className="flex items-center justify-center w-8 h-8 rounded-full bg-primary text-[white] hover:text-primary hover:bg-[white]"><InstagramOutlined /></a>
                                <a href='https://www.youtube.com/@SilverBluewater' className="flex items-center justify-center w-8 h-8 bg-blue-500 text-[white] rounded-full bg-primary hover:text-primary hover:bg-[white]"><YoutubeFilled /></a>
                                <a href='https://in.linkedin.com/company/shree-padmavati-online-services-private-limited' className="flex items-center justify-center w-8 h-8 rounded-full bg-primary text-[white] hover:text-primary hover:bg-[white]"><LinkedinOutlined /></a>
                            </div>
                        </div>
                        <img
                            src={bolltegrid}
                            className="hidden lg:block absolute top-[8px] left-[-120px] news-letter-weidget"
                            alt="bolltegrid"
                        />
                    </div>
                    <img
                        src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homiuce015.jpg"
                        className="object-cover w-[100%] h-[300px] md:h-[400px]"
                        alt="juice"
                    />
                </div>
            </section>
        </>
    )
}

export default NewsLetter