import React from 'react';
import { CiShoppingBasket } from "react-icons/ci";
import { Link } from 'react-router-dom';
import Mango from '../../assets/fruit juice mock ups-07.png'
import Orange from '../../assets/fruit juice mock ups-04.png'
import Berry from '../../assets/fruit juice mock ups-06.png'
import Pineapple from '../../assets/fruit juice mock ups-05.png'
import MixFruit from '../../assets/fruit juice mock ups-03.png'
import Lichi from '../../assets/fruit juice mock ups-02.png'
import Kiwi from '../../assets/fruit juice mock ups-1.png'
import Guava from '../../assets/fruit juice mock ups.png'
// import DrinkPineapple from '../../assets/drinkpinapple.png'
// import DrinkOrange from '../../assets/drinkorange.png'
// import DrinkPeach from '../../assets/dr'
// import DrinkGuava from '../../assets/n'
// import DrinkMango from '../../assets/n'
// import DrinkApple from '../../assets/image_2024_10_01T12_31_48_457Z.png'
// import DrinkStrawberry from '../../assets/drinkstraberry.png'
import energy from '../../assets/shiok energy front 1.png'

import Breadcrumb from '../Breadcrumb';

const ProductDetail = () => {
  return (
    <section className='container mx-auto p-sm'>
      <div className='container-bottom'>
        <Breadcrumb page={'Products'} />
        <div>
          <img alt='widget' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='m-auto w-[90px]' />
          <h2 className="text-5xl font-bold mb-4 text-center text-primary">Our Products</h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up mango`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Mango</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src= 'https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej012.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Mango} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up apple`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Pineapple</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej011.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Pineapple} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up orange`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Orange</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej09.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Orange} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up berry`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Wild Berry</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej011.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Berry} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up banana`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Mix Fruit</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej019-800x559.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={MixFruit} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up avocando`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Litchi</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej010.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Lichi} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up avocando`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Kiwi</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej010.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Kiwi} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up berry`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold text-primary">Juice</h2>
                <h2 className="text-3xl font-bold mb-4 text-primary">Guava</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej010.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={Guava} className='w-[90px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
          <div
            className={`shadow-lg mt-5 hover:shadow-xl transition-shadow duration-300 flex flex-row md:flex-row h-[332px] animate-slide-up berry`}
          >
            <div className="md:w-1/2 p-4 flex flex-col">
              <div className='text-left mt-5 pl-5 pt-10'>
                <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                <h2 className="text-3xl font-bold mb-4 text-primary">Energy Drink</h2>
                <Link to="/contact">
                  <button type="primary" className='bg-sky hover:bg-primary px-3 py-3 flex text-[white] items-center text-sm'><CiShoppingBasket className='mr-1' /><span> Contact us</span></button>
                </Link>
              </div>
            </div>
            <div className='w-[50%] relative'>
              {/* <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej010.png' className='w-[80%] bottom-0 absolute mb-[-25px] absolute ml-5 z-10' /> */}
              <img alt='img' src={energy} className='w-[100px] absolute bottom-[20px] right-[40px] lg:right-[60px]' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;