import React from 'react'
import { Tabs, Image } from 'antd';
import { DeploymentUnitOutlined, FullscreenOutlined, SlackOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import AboutCard from '../components/AboutCard';
import EnergyDrink from '../assets/shiok energy front 1.png'
import EnergyDrinkicon from '../assets/energy2.png'
import Mango from '../assets/fruit juice mock ups-07.png'
import Orange from '../assets/fruit juice mock ups-04.png'
import Berry from '../assets/fruit juice mock ups-06.png'
import useResponsiveSizes from '../components/hooks/useResponsiveSizes';

import Products from './Products';
import IconGrid from '../components/IconGrid';

function Home() {
    const { xs, md, lg, xl, xxl } = useResponsiveSizes()
    const items = [
        {
            key: '1',
            label: <>
                <div className='header-tab'>
                    <Image preview={false} width={100} src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej011.png' />
                    <span className='text-primary ml-5 text-lg font-bold'>Berry</span>
                </div>
            </>,
            children: <>
                <div className='container-bottom'>
                    <section className="showcase">
                        <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej08.png" className='h-[400px] object-contain lg:object-cover md:h-[600px] w-full' />
                        <div className="overlay">
                            <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-4.png" className="md:w-[50%] lg:w-[40%] lg:top-[40px] shadow shadow-none" />
                        </div>
                        <div className='flex justify-center'>
                            <p className='animate-fade-in font-bold text-primary text-[5rem] lg:text-[10rem]'>BERRY</p>
                            <img alt='img' src={Berry} className="animate-bounce-up widget-container top-[15%] w-[130px] md:w-[120px] lg:w-[160px] xl:w-[180px]" />
                            <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej011.png" className="animate-bounce-up elementor-weight top-[70%] left-[20%] md:left-[40%] lg:left-[40%] md:top-[60%] lg:top-[80%] w-[240px] md:w-[200px] lg:w-[340px]" />
                        </div>
                    </section>
                </div>
            </>,
        },
        {
            key: '2',
            label: <>
                <div className='header-tab'>
                    <Image preview={false} width={100} src={EnergyDrinkicon} />
                    <span className='text-primary md:ml-5 text-lg font-bold'>Energy Drink</span>
                </div>
            </>,
            children: <>
                <div className='container-bottom'>
                    <section className="showcase">
                        {/* <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej08.png" className='h-[400px] object-contain lg:object-cover md:h-[600px] w-full' /> */}
                        <div className="overlay">
                            {/* <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-4.png" className="md:w-[50%] lg:w-[40%] lg:top-[40px] shadow shadow-none" /> */}
                        </div>
                        <div className='flex justify-center'>
                            <p className='animate-fade-in font-bold text-primary text-[5rem] lg:text-[8rem] !top-[30%] xl:!top-[50%] lg:leading-[1]'>ENERGY DRINK</p>
                            <img alt='img' src={EnergyDrink} className="animate-bounce-up widget-container top-[15%] w-[160px] md:w-[160px] lg:w-[160px] xl:w-[220px]" />
                            {/* <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej011.png" className="animate-bounce-up elementor-weight top-[70%] left-[20%] md:left-[40%] lg:left-[40%] md:top-[60%] lg:top-[80%] w-[240px] md:w-[200px] lg:w-[340px]" /> */}
                        </div>
                    </section>
                </div>
            </>,
        },
        {
            key: '3',
            label: <>
                <div className='header-tab'>
                    <Image preview={false} width={100} src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej012.png' />
                    <span className='text-primary ml-5 text-lg font-bold'>Mango</span>
                </div>
            </>,
            children: <>
                <div className='container-bottom'>
                    <section className="showcase">
                        <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej08.png" className='h-[400px] md:h-[600px] w-full object-cover' />
                        <div className="overlay">
                            <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-3.png" className="md:w-[50%] lg:w-[40%] lg:top-[40px] shadow shadow-none" />
                        </div>
                        <div className='flex justify-center'>
                            <p className='animate-fade-in font-bold text-primary text-[5rem] lg:text-[10rem]'>MANGO</p>
                            <img alt='img' src={Mango} className="animate-bounce-up widget-container top-[15%] w-[130px] md:w-[120px] lg:w-[160px] xl:w-[180px]" />
                            <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej012.png" className="animate-bounce-up elementor-weight top-[70%] left-[20%] md:left-[40%] lg:left-[40%] md:top-[60%] lg:top-[80%] w-[240px] md:w-[200px] lg:w-[340px]" />
                        </div>
                    </section>
                </div>
            </>,
        },
        {
            key: '4',
            label: <>
                <div className='header-tab'>
                    <Image preview={false} width={100} src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej09.png' />
                    <span className='text-primary ml-5 text-lg font-bold'>Orange</span>
                </div>
            </>,
            children: <>
                <div className='container-bottom'>
                    <section className="showcase">
                        <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej08.png" className='h-[400px] md:h-[600px] w-full object-cover' />
                        <div className="overlay">
                            <img alt='img' src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-3.png" className="md:w-[50%] lg:w-[40%] lg:top-[40px] shadow shadow-none" />
                        </div>
                        <div className='flex justify-center'>
                            <p className='animate-fade-in font-bold text-primary text-[5rem] lg:text-[10rem]'>ORANGE</p>
                            <img alt='img' src={Orange} className="animate-bounce-up widget-container top-[15%] w-[130px] md:w-[120px] lg:w-[160px] xl:w-[180px]" />
                            <img alt='img' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej09.png' className="animate-bounce-up elementor-weight top-[70%] left-[20%] md:left-[40%] lg:left-[40%] md:top-[60%] lg:top-[80%] w-[240px] md:w-[200px] lg:w-[340px]" />
                        </div>
                    </section>
                </div>
            </>,
        },
    ];

    const icons = [
        { id: 1, icon: <DeploymentUnitOutlined />, description: 'No Sugar' },
        { id: 2, icon: <FullscreenOutlined />, description: 'organic system' },
        { id: 3, icon: <SlackOutlined />, description: 'Fresh Fruit' },
        { id: 4, icon: <AppstoreAddOutlined />, description: 'Naturally occurring' },
    ];

    return (
        <>
            <section className='container mx-auto'>
                <div className='items-center hero-tab'>
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            </section>
            {/* find outlets section  */}
            {/* <section className='container mx-auto outlet'>
                <Row className='justify-center'>
                    <Col xs={{ span: 24, }} lg={{ span: 24 }}>
                        <div className='mb-2 block text-left text-primary '>
                            <label>Enter Outlets area</label>
                        </div>
                        <div className='flex'>
                            <Input placeholder="Enter Location" className='p-5' />
                            <Button type="primary" className='outlet-btn rounded-none'>Find Juice outlets</Button>
                        </div>
                    </Col>
                </Row>
            </section> */}
            {/* outlet vedio section  */}
            <section className='lg:container lg:mx-auto'>
                <div className='flex bg-lightorange container-outer'>
                    <div className='w-[30%] relative'>
                        <img
                            alt='top-left-image'
                            src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej014.png' // Replace with your image URL
                            className='hover:animate-zoom-in-zoom-out absolute top-[-10%] right-[-10%] w-[260px] h-auto z-10'
                        />
                    </div>
                    <div className='relative w-[70%]'>
                        <img
                            alt='img'
                            src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homiuce02-800x534.jpg'
                            className='w-[100%] filter-blur h-[500px] md:h-[300px] lg:h-auto'
                        />
                        <div className='absolute md:left-1/4 transform gap-3 lg:gap-0 -translate-x-[0] top-1/2 transform -translate-y-[100%] md:-translate-x-[20%] md:-translate-y-[120%] lg:-translate-y-[100%] xl:-translate-y-[80%] text-left text-primary flex flex-col md:flex-row'>
                            <div>
                                <h1 className='text-8xl md:text-5xl font-bold'>99%</h1>
                                <p>Estate Growth used in Organic Industry</p>
                                <button type='text' className='text-primary rounded-none mt-5'>Find Juice outlets</button>
                            </div>
                            <div>
                                <iframe width={xs ? 240 : md ? 260 : lg ? 300 : xl ? 340 : xxl ? 400 : 380} height={xs ? 200 : md ? 220 : lg ? 300 : xl ? 260 : xxl ? 300 : 280} src="https://www.youtube.com/embed/cEnYX8hDEbw?si=8DuN7K0U4sluo7ab" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-5 mt-5">
                            {icons.map((item) => (
                                <div key={item.id} className="flex flex-col items-center p-4 transition-shadow duration-300">
                                    <div className="text-3xl text-primary mb-2">{item.icon}</div>
                                    <p className="text-lg text-center text-primary mt-5">{item.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* About card section  */}
            <AboutCard />
            <Products />
            <IconGrid />
        </>
    )
}

export default Home