import React, { useState, useRef } from 'react';
import { FaAlignJustify, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Image } from 'antd';
import logo from '../assets/SHIOK-removebg-preview 1.png'
import facebook from "../../src/assets/facebook.svg"
import linkedin from "../../src/assets/linkedin.svg"
import instagram from "../../src/assets/instagram.svg"
import Youtube from "../../src/assets/youtube.svg"

const Navbar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleMobileMenu = () => {
        setMobileMenuOpen((prev) => !prev);
    };

    return (
        <nav className="container !mt-0 !mb-0 mx-auto bg-gray-800" ref={dropdownRef}>
            <div className="flex justify-between items-center">
                <div className="order-1 md:order-2">
                    <div className='flex justify-center md:justify-end lg:justify-center md:flex-1'>
                        <Link to="/">
                            <Image
                                preview={false}
                                width={220}
                                src={logo}
                                className='mt-2 md:mx-0'
                            />
                        </Link>
                    </div>

                </div>
                <div className="order-1 md:order-3">
                    <div className="flex space-x-2 items-center md:justify-center">
                        <a href="https://in.linkedin.com/company/shree-padmavati-online-services-private-limited" className="text-gray-400">
                            <img
                                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                src={linkedin}
                                alt="placeholder"
                            />

                        </a>
                        <a href="https://www.facebook.com/people/Silver-Blue/61564584206686/" className="text-gray-400">
                            <img
                                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                src={facebook}
                                alt="placeholder"
                            />

                        </a>
                        <a href="https://www.instagram.com/silverbluewater/?igsh=MTk2NnZ2cWNlMzBzbg%3D%3D&utm_source=qr" className="text-gray-400">
                            <img
                                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                src={instagram}
                                alt="placeholder"
                            />
                        </a>
                        <a href="https://www.youtube.com/@SilverBluewater" className="text-gray-400">
                            <img
                                className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                src={Youtube}
                                alt="placeholder"
                            />
                        </a>
                        <Link to="/contact">
                            <button type="primary" className='bg-sky hover:bg-limegreen hover:scale-[90%] text-[white] px-3 py-2 ml-5 hidden lg:block'>Contact Us</button>
                        </Link>
                    </div>
                </div>
                <div className="order-2 md:order-1 hidden md:flex space-x-4">
                    <Link to="/" className='text-primary hover:text-sky'>Home</Link>
                    <Link to="/about" className='text-primary hover:text-sky'>About</Link>
                    <Link to="/products" className='text-primary hover:text-sky'>Products</Link>
                    <Link to="/events" className='text-primary hover:text-sky'>Events</Link>
                    <Link to="/contact" className='text-primary hover:text-sky'>Contact</Link>

                    {/* Mobile Menu Button */}
                </div>
                <div className="order-2 md:order-1 md:hidden">
                    <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
                        {mobileMenuOpen ? <FaTimes /> : <FaAlignJustify />}
                    </button>
                </div>
            </div>

            {/* Mobile Dropdown Menu */}
            {
                mobileMenuOpen && (
                    <div className="md:hidden flex flex-col mt-2 bg-gray-800 rounded-md shadow-lg">
                        <Link to="/" className='text-primary hover:text-sky mb-1 font-semibold' onClick={toggleMobileMenu}>Home</Link>
                        <Link to="/about" className='text-primary hover:text-sky mb-1 font-semibold' onClick={toggleMobileMenu}>About</Link>
                        <Link to="/products" className='text-primary hover:text-sky mb-1 font-semibold' onClick={toggleMobileMenu}>Products</Link>
                        <Link to="/events" className='text-primary hover:text-sky mb-1 font-semibold' onClick={toggleMobileMenu}>Events</Link>
                        <Link to="/contact" className='text-primary hover:text-sky mb-1 font-semibold' onClick={toggleMobileMenu}>Contact</Link>
                    </div>
                )
            }
        </nav >
    );
};

export default Navbar;