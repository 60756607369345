import React from 'react'
import { Row, Col, Image } from 'antd';
import useResponsiveSizes from './hooks/useResponsiveSizes';
import gridImage from '../assets/Group 4 (1).png'
import cardBottle from '../assets/CardBottle.png'

function AboutCard() {
    const { xs, md, lg, xl, xxl,xxxl } = useResponsiveSizes()
    return (
        <section className='about-section container mx-auto mt-5'>
            <Row className='gap-9'>
                <Col xs={{ span: 22 }} xl={{ span: 10 }}>
                    <div className='border border-[#05035A]'>
                        <figure className="pl-5 pt-5 flex flex-col items-left justify-center m-4 text-left bg-limelight">
                            <div className="mb-5 max-h-[300px]">
                                <h3 className="text-3xl font-bold text-primary">Our Mision</h3>
                                <p className="my-4 text-[#54595f] max-w-[70%] text-[12px] md:text-[18px]">To redefine the premium beverage category by setting new standards in quality, taste, and elegance, becoming the go-to choice for discerning consumers who seek a refined and healthful beverage experience.</p>
                            </div>
                            <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            </div>
                            <div className="relative about-section-img">
                                <div className="absolute inset-0 card-widget">
                                    <img
                                        src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-2.png"
                                        alt="Background"
                                        className="h-full object-cover"
                                    />
                                </div>
                                <Image preview={false} width={xs ? 200 : md ? 200 : lg ? 210 : xl ? 250 : xxl ? 260 : xxxl ? 280 : 280} src={gridImage} />
                            </div>
                        </figure>
                    </div>
                </Col>
                <Col xs={{ span: 22 }} xl={{ span: 10 }}>
                    <div className='border border-[#05035A]'>
                        <figure className="pl-5 pt-5 flex flex-col items-left justify-center m-4 text-left bg-lightorange">
                            <div className=" mb-5 max-h-[300px]">
                                <h3 className="text-3xl font-bold text-primary">Our Mision</h3>
                                <p className="my-4 text-[#54595f] max-w-[70%] text-[12px] md:text-[18px]">Our mission is to craft exceptional beverages that combine the purity of nature with innovative processing techniques. By using the finest ingredients and adhering to rigorous quality standardss.</p>
                            </div>
                            <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            </div>
                            <div className="relative about-section-img">
                                <div className="absolute inset-0 card-widget">
                                    <img
                                        src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/background-2.png"
                                        alt="Background"
                                        className="h-full object-cover"
                                    />
                                </div>
                                <Image preview={false} width={xs ? 200 : 350} src={cardBottle} />
                            </div>
                        </figure>
                        <div className='side-widget'>
                            <img alt='widget' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej021-800x816.png' className='absolute top-0 right-0 w-[40%] md:w-[30%] xl:w-[50%] h-auto' />
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default AboutCard