import React from 'react'

import facebook from "../../src/assets/facebook.svg"
import linkedin from "../../src/assets/linkedin.svg"
import Youtube from "../../src/assets/youtube.svg"
import instagram from "../../src/assets/instagram.svg"

const Footer = () => {
    return (
        <>
            <section className='bg-[#e2eeed]'>
                <div className='grid grid-cols-1 container !my-0 md:grid-cols-3 gap-10 mx-auto py-20'>
                    <div className=' md:border-r-2 text-center pr-5 lg:pr-10'>
                        <h3 className='text-lg md:text-xl lg:text-2xl font-bold text-primary '>
                            Address
                        </h3>
                        <p className='text-base lg:text-lg text-[black] mt-5'>
                           303-305 Timesquare opp. Iscon arcade CG Road Navarnagpura Ahmedabad-380009</p>
                    </div>
                    <div className=' md:border-r-2 text-center  pr-5 lg:pr-10'>
                        <h3 className='text-lg md:text-xl lg:text-2xl text-primary font-bold'>
                            Follow
                        </h3>
                        <div className="flex space-x-2 mt-5 items-center justify-center">
                            <a href="https://in.linkedin.com/company/shree-padmavati-online-services-private-limited" className="text-gray-400">
                                <img
                                    className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                    src={linkedin}
                                    alt="linkedin"
                                />

                            </a>
                            <a href="https://www.facebook.com/people/Silver-Blue/61564584206686/" className="text-gray-400">
                                <img
                                    className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                    src={facebook}
                                    alt="facebook"
                                />

                            </a>
                            <a href="https://www.instagram.com/silverbluewater/?igsh=MTk2NnZ2cWNlMzBzbg%3D%3D&utm_source=qr" className="text-gray-400">
                                <img
                                    className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                    src={instagram}
                                    alt="instagram"
                                />
                            </a>
                            <a href="https://www.youtube.com/@SilverBluewater" className="text-gray-400">
                                <img
                                    className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                                    src={Youtube}
                                    alt="Youtube"
                                />
                            </a>
                        </div>
                    </div>
                    <div className=' md:text-right'>
                        <h3 className='text-lg md:text-xl lg:text-2xl   text-primary font-bold'>
                            Contact
                        </h3>
                        <div className='text-[black] mt-5 '>
                            <a href='tel:+91 88868 40840' className='text-base lg:text-lg'>
                                <p>+91 7777932777</p>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='text-center pb-10 container-1'>
                    <p className='text-base lg:text-lg text-[black]' >
                        © Copyright 2024 by Shiok</p>
                </div>
            </section>
        </>
    )
}

export default Footer