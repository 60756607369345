import React from 'react'
import IconGrid from '../components/IconGrid'
import Breadcrumb from '../components/Breadcrumb'

const AboutPage = () => {
  return (
    <>
      <section className="container mx-auto !mt-0">
        {/* breadcrumb  */}
        <Breadcrumb page={'About'} />
        <div>
          <div class="flex flex-col md:flex-row">
            <div class="relative w-full md:w-1/2 px-5 h-[400px]">
              <div className=''>
                <img src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/Mask-Group-15.jpg" alt="About Us" class="w-full h-full object-cover max-h-[400px] filter-blur" />
              </div>
              <div className='absolute inset-0 bg-black bg-opacity-50 p-5 text-primary pt-5'>
                <div class="w-full p-[1rem] lg:p-[3rem] relative z-10">
                  <img alt='placeholder' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
                  <h2 class="text-white text-2xl md:text-3xl font-bold text-left mt-5">
                    Our Story
                  </h2>
                  <p className='mt-5 text-md text-left'>
                    Shiok was born out of a desire to redefine the beverage experience for the discerning consumer. With a rich heritage under the Silver Blue brand, Shiok represents a commitment to luxury, quality, and innovation. What began as a quest to craft the finest fruit juices soon expanded into the realm of premium alkaline water, driven by a passion for health and wellness.
                  </p>
                </div>
                <div>
                  <img alt='placeholder' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej021-800x816.png' className='absolute tranform translate-x-[20%]  w-[150px] md:w-[150px] lg:w-[220px] right-4 md:right-0 top-[70%]' />
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center w-full md:w-1/2 px-5 md:[mt-0] h-[400px]">
              <div>
                <p class="text-primary text-gray-700 md:text-lg text-left mx-auto lg:w-1/2">
                  The Shiok team is a collective of visionaries, nutritionists, and designers who work in harmony to bring this vision to life. Their relentless pursuit of perfection is reflected in every bottle, whether it's a refreshing fruit juice or an elegantly packaged alkaline water. Together, they have built a brand that stands for sophistication, health, and a touch of indulgence.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='md:container'>
          <div className="text-left md:my-10">
            <img alt='placeholder' src='https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homej023.png' className='w-[50px]' />
            <h1 className="text-5xl text-primary font-bold">Brand Positioning</h1>
          </div>
          <div className="relative mb-4">
            <img
              src="https://demo2.strongtheme.com/juice/wp-content/uploads/sites/12/2021/10/homiuce017.jpg"
              alt="Juice image"
              className="w-full h-[600px] object-cover"
            />
            <div className="absolute inset-y-0 right-0 xl:w-1/2 bg-black bg-opacity-50 flex items-center justify-center bg-[black] opacity-100">
              <div className="text-[white] md:max-w-[50%] text-xl font-bold">
                Shiok is positioned as the premier choice in the premium beverage market, offering an unmatched combination of high-quality ingredients, sophisticated flavors, and elegant packaging. Our unique selling proposition (USP) lies in our ability to deliver a superior taste experience that is both luxurious and healthful, whether through our premium fruit juices or our innovative alkaline waters.
              </div>
            </div>
          </div>
        </div>
      </section>

      <IconGrid />
    </>
  )
}

export default AboutPage