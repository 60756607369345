import './App.css';
import '../src/style.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import ProductList from './components/product/ProductList';
import NewsLetter from './components/NewsLetter';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import Events from './pages/Events';
import EventDetails from './pages/EventDetails';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/events" element={<Events />} />
          <Route path="events/:title" element={<EventDetails />}/>
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <NewsLetter />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
